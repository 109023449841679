* {
  font-family: Arial, Helvetica, sans-serif, Helvetica, sans-serif;
  margin: 0;
}

.home{
  overflow: auto;
  width: 100%;
 
}

.home-page {
  background-color: #193c6d;
  color: #e2e7ed;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;

}

button {
  background-color: #193c6d;
  color: #e2e7ed;
  border: 1px solid #e2e7ed;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 10px;
}
.title-bar {
  background-color: #193c6d;
  width: 100%;
  color: #f2f2f2;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

}
.bar {
  width: 100%;
  height: 5px;
  background-color: #a3cef1;
}

.nav-links a {
  text-decoration: none;
  color: #f2f2f2;
  display: inline-block;
  padding: 14px 14px;
}

.nav-links a:hover,
.active {
  text-decoration: none;
  color: #a3cef1;

}

.App{
  text-align: center;
}

.title{
  text-align: center;
  text-decoration: none;
}

.portfolio-page{
  text-decoration: none;
}
a{
  text-decoration: none;
  color: #000;
}
.form {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 90%;
}
.formContainer {
  width: 100%;
  margin: 0;
}

.about{
  max-width: 1600px;

}